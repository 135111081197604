<template>
  <section class="RequestsTable" :style="{minWidth: '800px'}">
    <div class="RequestsTable__filters filters row">
      <div class="col-md-4">
        <InputDropdown
            placeholder="Select matchday"
            :allow-empty="false"
            trackLabel="optionLabel"
            :options="matchDaysList"
            v-model="matchDay"
        />
      </div>
      <div class="col-md-4">
        <InputDropdown
            placeholder="Select match"
            :allow-empty="false"
            trackLabel="name"
            :options="matchesList"
            v-model="match"
        />
      </div>
      <div class="col-md-2">
        <Button
            type="link"
            @click="clearFilters"
        >
          Clear filters
        </Button>
      </div>
      <div class="col-md-2">
          <div class="RequestsTable__filters-btn-wrapper float-right">
            <Button
                v-if="$store.getters['user/permissions'].includes('bookedActivities:createAPI')"
                type="primary"
                @click="redirectToRequestActivation()"
            >
              Submit new request
            </Button>
          </div>
        </div>
    </div>

    <Table
        :collection="activationsList"
        :columns="tableColumns"
        @on-sort="onSort"
    >
      <template v-slot:table-cell-requestDate="slot">
        {{ moment(slot.data).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:table-cell-organization="slot">
        {{ getOrganizationFromSlot(slot) }}
      </template>
      <template v-slot:table-cell-designation="slot">
        {{ getDesignationFromSlot(slot) }}
      </template>
      <template v-slot:table-cell-status="slot">
        <span :style="{ color: getStatusColor(slot.data) }">
          {{ slot.model.status }}
        </span>
      </template>
      <template v-slot:table-cell-actions="slot">
        <Button
            class="RequestsTable__button-delete"
            @click="deleteActivation(slot.model)"
            icon="BIN_OUTLINE"
            type="round"
            :disabled="slot.model.status !== 'Requested'"
        />

        <Button
          class="RequestsTable__button-edit"
          icon="EDIT_OUTLINE"
          form="round"
          type="primary"
          :disabled="slot.model.status !== 'Requested'"
          @click.native="updateActivation(slot.model)"
        />

      <Button
          form="round"
          type="primary"
          icon="RIGHT_OUTLINE"
          @click.native="redirectToDetails(slot.model.bookingId, slot.model)"
      />
    </template>
    </Table>
    <TableControls @setLimit="updateLimit($event)" @selectPage="updatePage($event)"
                   :count="count" :limit="limit" :offset="offset" />
    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
  </section>
</template>

<script>
import upperFirst from 'lodash.upperfirst';
import moment from "moment";

import Table from '@/components/Table/Table.component';
import TableControls from "@/components/Table/TableControls.component";
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import Button from "@/components/Button/Button.component";

import RouterNames from '@/router/route.names';

import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";
import get from "lodash.get";
import {DtoApiDeleteActivationRequest} from "../../../classes/dto/api/sponsor/dto.api.delete-activation.request";

export default {
  name: 'DDMCAF-ActivationsTable',
  components: {Table, Button, InputDropdown, TableControls},
  data: () => ({
    matchDay: null,
    match: null,
    tableColumns: []
  }),
  watch: {
    matchDay(newVal) {
      if (newVal) {
        this.getActivations(newVal)
        this.getMatches(newVal)
      }
    },
    match(newVal) {
      if (newVal) {
        this.getActivations(newVal)
      }
    },
    account(account) {
      this.pageMemo = this.page;
      this.page = 1;
      this.getActivations(account.id);
    },
  },
  computed: {
    activationsListMeta: {
      get: function () {
        return get(this, '$store.state.sponsorActivationsList.activationsListMeta')
      },
    },
    account() {
      return get(this, '$store.state.user.account', []);
    },
    pageComputed() {
      return this.request.$loading
          ? this.pageMemo
          : this.page;
    },
    activationsList() {
      return get(this, '$store.state.sponsorActivationsList.activationsList')
    },
    matchDaysList() {
      return get(this, '$store.state.event.matchDaysList')
    },
    matchesList() {
      return get(this, '$store.state.event.matchesList')
    },
    count() {
      return this.activationsListMeta ? this.activationsListMeta.count : 0;
    },
    offset() {
      return this.activationsListMeta ? this.activationsListMeta.offset : 0;
    },
    limit: {
      get: function () {
        return get(this, '$store.state.sponsorActivationsList.limit')
      },
      set: function (newVal) {
        this.$store.state.sponsorActivationsList.limit = newVal
      }
    },
    page: {
      get: function () {
        return get(this, '$store.state.sponsorActivationsList.page')
      },
      set: function (newVal) {
        this.$store.state.sponsorActivationsList.page = newVal
      }
    },
    pages: {
      get: function () {
        return get(this, '$store.state.sponsorActivationsList.pages')
      },
      set: function (newVal) {
        this.$store.state.sponsorActivationsList.pages = newVal
      }
    },
    orderBy: {
      get: function () { return get(this, '$store.state.sponsorActivationsList.orderBy') },
      set: function (newVal) { this.$store.state.sponsorActivationsList.orderBy = newVal }
    },
    orderByDirection: {
      get: function () {
        return get(this, '$store.state.sponsorActivationsList.orderByDirection')
      },
      set: function (newVal) {
        this.$store.state.sponsorActivationsList.orderByDirection = newVal
      }
    },
  },
  methods: {
    async deleteActivation(activation) {
      this.$confirm(
          {
            message: `Are you sure you want to delete this activation?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeleteActivationRequest().$deleteActivation(activation.bookingId, this.$store.state.user.account.id).then(() => {
                  this.getActivations()
                })
              }
            }
          })

    },
    updateActivation(data) {
      this.$emit('activationUpdate', data)
    },
    getCompetitionFromSlot(slot) {
      return slot.model.competition.name
    },
    getSeasonFromSlot(slot) {
      return slot.model.season.name
    },
    getOrganizationFromSlot(slot) {
      return slot.model.organization.name
    },
    getDesignationFromSlot(slot) {
      let result = ""

      if (slot.model.subcategories.length > 0) {
        slot.model.subcategories.forEach((item, index) => {
          result += item.subcategory.name + "("+item.amount+")"
          if (index+1 !== slot.model.subcategories.length) {
            result += ','
          }
          result += ' '
        })
      }

      return result
    },
    moment,
    upperFirst,
    redirectToDetails(request) {
      if (!request) return;
      this.$router.push({
        name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationDetails,
        params: {request}
      })
    },
    redirectToRequestActivation() {
      this.$emit('tabchange', 'EventInfo')
      this.$store.commit('sponsorActivation/clearState')
      this.$router.push({name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivation})
    },
    onSort(event) {
      if (event.sort) {
        this.orderBy = event.sort
        this.orderByDirection = this.orderByDirection === 'desc' ? 'asc' : 'desc'
        this.getActivations()
      }
    },
    updateLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.getActivations()
    },
    updatePage(page) {
      this.page = page;
      this.getActivations()
    },
    getActivations(data) {
      this.$store.dispatch('sponsorActivationsList/getActivations', data)
    },
    getMatches(data) {
      this.$store.dispatch('event/getMatches', {...data, withRequestsFor: 'sponsor_activation_request'})
    },
    clearFilters() {
      if (this.matchDay || this.match) {
        this.matchDay = null
        this.match = null
        this.getActivations()
      }
    },
    setTableColumns() {
      let models = [
        new TableColumnModel('requestDate', 'Request Date', '100px', 'created_at'),
        new TableColumnModel('matchTitle', 'Match', '150px', 'matchId'),
        new TableColumnModel('organization', 'Organisation', '150px'),
        new TableColumnModel('activityName', 'Activation', '100px', 'activityId'),
        new TableColumnModel('designation', 'Designation', '200px'),
        new TableColumnModel('contactFirstName', 'Contact First Name', '100px', 'contactFirstName'),
        new TableColumnModel('contactLastName', 'Contact Last Name', '100px', 'contactLastName'),
        new TableColumnModel('status', 'Status', '100px', 'status'),
        new TableColumnModel('actions', '', '80px'),
      ]

      this.tableColumns = new TableColumnsCollection(models);
    },
    getStatusColor(status) {
      return ({
        Approved: '#7fcc90',
        Rejected: '#f68476',
        'In Review': '#fac991',
        Requested: '#a8a8a8',
        Draft: '#a8a8a8'
      })[status] || '#a8a8a8';
    },
  },
  created() {
    this.setTableColumns()

    this.getActivations()
  },
}
</script>

<style lang="scss">
@import "src/styles/main.scss";


.confirm-dialog {
  .vc-btn:last-child {
    color: $danger-color;
  }

  .vc-btn:first-child {
    color: $primary-color;
  }
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;

  .pagination-perPage-select {
    display: flex;
    align-items: center;
    justify-content: center;

    .InputDropdown {
      margin: 0;

      .multiselect {
        margin: 0;
      }
    }
  }
}

.pagination-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 43px;
}


.RequestsTable {
  .button-component__icon {
    font-size: 28px !important;
  }

  .Table__table-cell-container {
    justify-content: space-between;
  }

  &__button-delete,
  &__button-edit {
    height: auto;
    background: none;

    &[disabled] {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__button-delete {
    .button-component__icon {
      color: $danger-color !important;
      transform: none;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    > div {
      .InputDropdown:first-child, .button-component:first-child {
        margin-right: 15px;
      }
    }
  }

  &__filters-input-wrapper {
    display: flex;
  }
}
</style>
