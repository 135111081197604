<template>
  <div class="ContactsPopup">
    <div class="ContactsPopup__window">
      <div class="ContactsPopup__window-container" style="padding: 20px">
      <div class="ContactsPopup__close-popup">
        <span @click="closePopup">X</span>
      </div>

      <Headline :size="3" class="text-center">Edit activation request</Headline>

      <div class="ContactsPopup__fields">
        <ContactForm
          :activation="model"
        />
      </div>

      <div class="ContactsPopup__errors">
        <div v-for="value in errors">
          {{value}}
        </div>
      </div>

      <div class="btn-wrapper">
        <Button
          type="primary"
          :disabled="isValid"
          @click="updateActivation(model)"
        >
          Update
        </Button>
        <pulse-loader :loading="showLoader" :color="color" :size="size" ></pulse-loader>
      </div>

      </div>
    </div>
  </div>
</template>

<script>
import {ActivationModel} from "@/classes/models/Activation.model";

import get from "lodash.get";
import Button from "@/components/Button/Button.component";
import Headline from "@/components/Headline/Headline.component";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ContactForm from "@/views/Dashboard/SponsorActivation/components/ContactForm.component.vue";
import {PhoneNumberUtil} from "google-libphonenumber";
import {DtoApiUpdateSponsorActivation} from "@/classes/dto/api/sponsor/dto.api.update-sponsor-activation.request";

export default {
  name: "DDMCAF-ActivationsPopup",
  components: {
    ContactForm,
    Headline,
    PulseLoader,
    Button,
  },
  data: () => ({
    api: new DtoApiUpdateSponsorActivation(),
    model: new ActivationModel(),
    showLoader: false,
    color: "#00A9A0",
    size: "12px",
    errors: []
  }),
  props: ['activation'],
  computed: {
    isValid() {
      // activation missing contact info or participant amounts
      return (
        this.model.personInfo.contactEmail && this.model.personInfo.contactPhone &&
        this.model.personInfo.contactFirstName && this.model.personInfo.contactLastName &&
        this.model.subcategories.filter((s) => !s.amount || s.amount < 0).length > 0
      );
    }
  },
  methods: {
    async updateActivation() {
      const organizationId = get(this, '$store.state.user.account.id');
      const bookingId = this.model.bookingId;
      const subcategories = this.model.subcategories.map(item => ({id: item.subcategoryId, amount: item.amount}))
      let conceptEncoded, conceptName;
      if(this.model.concepts && this.model.concepts.length >= 1) {
        conceptEncoded = this.model.concepts[0].url;
        conceptName = this.model.concepts[0].filename.split('.').slice(0, -1).join('.');
      }
      let bookedActivity = {
        organizationId,
        subcategories,
        conceptEncoded: conceptEncoded || null,
        conceptName: conceptName || null,
        ...this.model.personInfo,
      }

      bookedActivity.contactPhone = this.getMobileWithCountryCode(this.model.personInfo)

      this.showLoader = true
      this.request = await this.api.$update(bookingId, bookedActivity)
      if (this.request.$status === 422) {
        this.errors = this.request.$response.data.data.errors;
      }
      else if(!this.request.$error) {
        for (const key in this.activation) {
          this.activation[key] = this.model[key]
        }
        this.closePopup()
      }
      this.showLoader = false
    },
    getMobileWithCountryCode(personInfo)
    {
      const util = PhoneNumberUtil.getInstance();

      let result = ''

      try {
        const country = personInfo.phoneCountry.iso2;
        const parsed = util.parse(personInfo.contactPhone, country);
        const code = parsed.getCountryCode();
        const number = parsed.getNationalNumber();
        result = '+' + code + number
      } catch (error) {
        result = personInfo.phoneCountry.dialCode + personInfo.contactPhone
      }

      return result;
    },
    closePopup() {
      this.$emit('closePopup')
    },
  },
  created() {
    this.model = Object.assign(this.model, this.activation)
  }
}
</script>

<style lang="scss">
.ContactsPopup {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 100;
  padding: 20px;
  overflow: scroll;

  &__errors {
    color: darkred;
    font-size: 12px;
  }

  &__window {
    width: 800px;
    max-width: 90vw;
    padding: 20px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__container {
    padding: 20px;
  }

  &__close-popup {
    width: 100%;
    text-align: right;
    font-weight: bold;
    margin-bottom: 20px;
    span {
      cursor: pointer;
    }
  }

  .btn-wrapper {
    margin-top: 30px;
    text-align: center;
  }

  .Input__input {
    width: 100% !important;
  }
}
</style>
